<template>
<div>
    <lottie :options="defaultOptions" :height="400"  v-on:animCreated="handleAnimation" />
</div>
</template>

<script>
import Lottie from './lottie.vue';
import * as animationData from './resource/delivery.json';

export default {
    components: {
        'lottie': Lottie
    },
    data() {
        return {
            defaultOptions: {
                animationData: animationData.default
            },
            animationSpeed: 0.5
        }
    },
    methods: {
        handleAnimation: function (anim) {
            this.anim = anim;
        },

        stop: function () {
            this.anim.stop();
        },

        play: function () {
            this.anim.play();
        },

        pause: function () {
            this.anim.pause();
        },

        onSpeedChange: function () {
            this.anim.setSpeed(this.animationSpeed);
        }
    }
}
</script>
